<template>
 <v-app>
    <v-container fluid>
      <v-row class="text-center">
        <v-col class="mb-4">
          <h3 class="display-2 font-weight-bold mb-3">Haz Parte De Esta Gran Oportunidad</h3>
        </v-col>
      </v-row>
          <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-alert border="left" colored-border color="success">
                        <p class="font-weight-black">
                          1. Quieres Tener Mas Clientes ? 
                        </p>
                        <v-divider></v-divider>
                        <p class="font-weight-black">
                          2. No sabes Como Atraerlos ?
                        </p>
                        <v-divider></v-divider>
                        <p class="font-weight-black">
                          3. No tienes Conocimientos en Marketing
                        </p>
                        <v-divider></v-divider>
                        <p class="font-weight-black">
                          4. Quieres ser Numero Uno a Nivel Municipal y sus alrededores.
                        </p>
                        <v-divider></v-divider>
                        <p class="font-weight-black">
                          5. Que esperas Unete al Cambio Digital
                        </p>
                        <v-divider></v-divider>
                        <p class="font-weight-black">
                         Contactanos por nuestros medios digitales:
                        </p>
                      </v-alert>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="text-md-center">
                      <v-card elevation="24">
                        <v-alert class="text-center" dense dark color="success">
                          <v-icon>mdi-phone-classic </v-icon
                          ><strong>Contactanos YA</strong
                          ><v-icon> mdi-phone-classic </v-icon>
                        </v-alert>
                        <h5 class="text-h5 text-center">Deja Todo En Nuestras Manos Expertas</h5>
                        <br>
                         <v-row class="justify-center">
                                <!-- <v-btn  class="ma-2" rounded color="primary" href="https://www.facebook.com/" target="_blank"><v-icon left size="20">mdi-facebook</v-icon>
                              facebook</v-btn> -->
                              <v-btn  class="ma-2" rounded color="success" href="https://api.whatsapp.com/send?phone=3108998360" target="_blank"><v-icon left size="20">mdi-whatsapp</v-icon>
                              whatsapp</v-btn>
                                <!-- <v-btn  class="ma-2" rounded color="error" href="https://www.instagram.com/" target="_blank"><v-icon left size="20">mdi-instagram</v-icon>
                              instagram</v-btn> -->
                        </v-row>
                        <br>
                        <h1 class="text-h3 text-center">Tu Plaza Polonuevo te Espera</h1>
                        <v-alert class="text-center" dense dark color="success">
                          <strong
                            >No te quedes Por fuera</strong
                          >
                        </v-alert>
                      </v-card>
                    </v-col>
                  </v-row>
      <!-- ------------------------------------------------------------------------- -->
    </v-container>
  </v-app>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      
    };
  },
  created() {
 
  },
  methods: {
  
  },
};
</script>